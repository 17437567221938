export const siteName = 'Pretrained.ai';
export const logoUrl = '/assets/logo.svg';
export const globalLocales = [
  'general',
  'navigation',
];

export const navigationMenuItems = [
  {
    name: 'navigation.menu-items.endpoints',
    href: '/endpoints',
    permission: 'view_endpoint',
  },
  {
    name: 'navigation.menu-items.logs',
    href: '/logs',
    permission: 'view_endpointcall',
  },
  {
    name: 'navigation.menu-items.billing',
    href: '/billing',
    permission: 'view_subscription',
  },
];
