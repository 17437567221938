const DEFAULT_LOCALE = 'en';

export default function loadMessages(namespaces, locale) {
  if (!Array.isArray(namespaces)) {
    namespaces = [namespaces];
  }

  // eslint-disable-next-line global-require,import/no-dynamic-require
  const p = namespaces.map((namespace) => require(`./${locale || DEFAULT_LOCALE}/${namespace.split('.').join('/')}.json`));

  return Promise.all(p).then((results) => {
    const messages = {};

    namespaces.forEach((namespace, i) => {
      const nodes = namespace.split('.');
      const leafNode = nodes.pop(nodes.length - 1);

      let nodeMessages = messages;
      nodes.forEach((n) => {
        nodeMessages[n] = {};
        nodeMessages = nodeMessages[n];
      });

      nodeMessages[leafNode] = results[i];
    });

    return messages;
  });
}