const subscribers = {};

export function publishEvent(e, ...args) {
  if (subscribers[e]) {
    subscribers[e].forEach((subscriberCallback) => {
      try {
        subscriberCallback(...args);
      } catch (ex) {
        console.error(
        'Invoking',
        subscriberCallback,
        `for ${e} with`,
        args,
        'failed with exception',
        ex);

      }
    });
  }
}

export function subscribeEvent(e, callback) {
  if (!subscribers[e]) {
    subscribers[e] = [];
  }

  subscribers[e].push(callback);
}